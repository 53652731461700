// Testing shit

.trails-text {
  font-family: $font-primary;
  text-align: left;
  position: relative;
  width: 100%;
  line-height: 3.5rem;
  color: black;
  font-size: 2rem;
  font-weight: 200;
  will-change: transform, opacity;
  list-style: none;

  @include respond(phone) {
    height: auto;
  }

  &::before {
    content: '';
    width: 7px;
    height: 1px;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1.5rem, 1.8rem);

    @include respond(phone) {
      width: 5px;
    }
  }
}

.quad-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 18rem;

  @include respond(tabLand) {
    flex-direction: column;
  }

  @include respond(phone) {
    margin-top: 18rem;
  }
}

.quad-title {
  transform: scale(3);
  transform-origin: 0% 50%;
  height: 3rem;
  margin-bottom: 3rem;

  @include respond(tabPort) {
    transform: scale(1);
  }
}

.quad-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;
  padding: 0 5rem 2rem 5rem;

  @include respond(phone) {
    padding: 0 5rem 2rem 0;
  }
}

.quad-download-btn {
  width: 18rem;
  height: 4.5rem;

  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.42);
  border-radius: 1rem;
  background-image: linear-gradient(270deg, #000000 0%, #545454 100%);
  // Style for "download p"

  color: #707070;
  font-family: $font-primary;
  font-size: 1.7rem;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.42);
    transform: translateY(3px);
  }
}

.quad-link {
  text-decoration: none;
}
