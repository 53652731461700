.App {
  text-align: center;
  position: relative;
  // flex: 1;
  height: 100%;
  width: 100%;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7rem;
  z-index: 100;
  transform: scale(1.5);
  transform-origin: 0% 50%;

  @include respond(phone) {
    padding: 7rem 5rem 7rem 5rem;
    transform: scale(1);
  }

  &-underline {
    position: absolute;
    left: 0;
    height: 1px;
    width: 18rem;
    margin-top: 1rem;
    background: -moz-linear-gradient(0% 50% 0deg, rgba(197, 49, 50, 1) 0%, rgba(242, 100, 69, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(197, 49, 50, 1) 0%, rgba(242, 100, 69, 1) 100%);
    background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(197, 49, 50, 1)), color-stop(1, rgba(242, 100, 69, 1)));
    background: -o-linear-gradient(0deg, rgba(197, 49, 50, 1) 0%, rgba(242, 100, 69, 1) 100%);
    background: -ms-linear-gradient(0deg, rgba(197, 49, 50, 1) 0%, rgba(242, 100, 69, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#C53132', endColorstr='#F26445' ,GradientType=0)";
    background: linear-gradient(90deg, rgba(197, 49, 50, 1) 0%, rgba(242, 100, 69, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C53132',endColorstr='#F26445' , GradientType=1);

    @include respond(phone) {
      width: 22rem;
    }
  }
}

.main-wrapper {
  // position: absolute;
  top: 0;
  flex: 1;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  z-index: -2;
  position: relative;

  @include respond(tabPort) {
  }
}

.particles {
  position: relative;
  // z-index: -100;
  width: 100vw;
  // height: 100vh;

  &-children {
    position: absolute;
    top: 0;
    // height: 100vh;
    z-index: 1;

    @include respond(tabPort) {
      height: auto;
    }
  }
}

.nav {
  position: absolute;
  top: 6.5rem;
  right: 5rem;

  &-link {
    text-decoration: none;
    color: #222222;
    font-family: $font-primary;
    font-size: 2.6rem;
    font-weight: 100;
    margin: 1rem;
  }
}

.center-section {
  width: 100vw;
  padding: 0 18rem 0 18rem;

  @include respond(tabPort) {
    padding: 0 0 0 10rem;
  }

  @include respond(phone) {
    padding: 0 0 0 5rem;
  }
}

.title {
  padding: 3rem 0 0 0;
  width: 80.6rem;
  height: 16.8rem;
  color: #222222;
  font-family: $font-primary;
  font-size: 7.6rem;
  font-weight: 100;
  text-align: left;
  margin-right: 3rem;
  display: flex;
  position: relative;

  &-rotating {
    // right: 0;
    color: #222222;
    height: 26rem;

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 3rem;
    display: inline;
    // transform: translateX(100%);

    @include respond(phone) {
      height: 14rem;
      max-width: 90%;
    }
  }

  &-colour {
    color: #ff5733;
  }

  &-invisible {
    opacity: 0;
  }

  @include respond(tabPort) {
    width: 100%;
    height: 30rem;
  }

  @include respond(phone) {
    font-size: 5rem;
  }
}

.subtitle {
  width: 69.3rem;
  // height: 23rem;ß
  color: #222222;
  font-family: $font-primary;
  font-size: 3.3rem;
  font-weight: 200;
  text-align: left;
  padding: 0 3rem 0 0;
  margin-top: 23rem;

  @include respond(tabPort) {
    width: 100%;
    height: auto;
    margin-top: 8rem;
  }

  @include respond(phone) {
    font-size: 2.8rem;
    margin-top: 1rem;
  }
}

.contact-button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 5rem;
  // transform: translate(-50%, -50%);
  z-index: 1;
  text-decoration: none;

  width: 12.7rem;
  height: 3.9rem;
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ff5733;
  font-family: $font-primary;
  font-size: 2rem;
  font-weight: 200;
  padding-bottom: 3px;
}

.dud {
  color: #cccccc;
}

.homepage {
 &-btf {
   height: 50rem;
 } 

 &__our-clients {
   width: 100%;
   background-color: #cccccc;
 }
}