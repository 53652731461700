.clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 14rem;

  &-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 130rem;
  }

  &__image {
    margin: 3rem;
    scale: 0.75;

    @include respond(phone) {
      margin: -1.5rem 0 0 0;
    }
  }

  &-title {
    font-size: 5rem;
    letter-spacing: 10px;
    padding: 3rem 0 0 0;
    width: 100%;
    color: #ff5733;
    font-family: $font-primary;
    font-size: 7.6rem;
    font-weight: 100;
    text-align: left;
    margin-right: 3rem;
    display: flex;
    position: relative;

    @include respond(phone) {
      height: 14rem;
      max-width: 90%;
    }
  }

  &-subtitle {
    padding: 3rem 0 0 0;
    color: #222222;
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 100;
    // text-align: left;
    margin-right: 3rem;
    display: flex;
  }
}
