$font-primary: acumin-pro, sans-serif;

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tabPort {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tabLand {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == bigDesktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

@import './app';
@import './quad';
@import './clients';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-size: 62.5%;

  @include respond(tabLand) {
    // width < 1200
    font-size: 56.25%; // 9px
  }

  @include respond(tabPort) {
    // width < 900
    font-size: 50%; // 8px
    height: auto;
  }

  @include respond(bigDesktop) {
    font-size: 75%; // 12px
  }
}
